import React from 'react';
import { Path } from 'app/const/Path';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import styled from 'styled-components/macro';
import Typography from '@material-ui/core/Typography';
import ContainedButton from 'app/components/Buttons/ContainedButton';

const MessageContainer = styled((props) => <Box {...props} />)`
  align-items: center;
  // border: 1px red solid;
  @media (max-width: 960px) {
    flex-direction: column;
    align-items: normal;
  }
`;

const Typo = styled((props) => <Typography {...props} />)`
  && {
    margin-right: 32px;
    align-self: center;
    @media (max-width: 960px) {
      margin-bottom: 8px;
    }
  }
`;

const Button = styled((props) => <ContainedButton {...props} />)`
  && {
    height: 48px;
    min-width: 146px;
    @media (max-width: 960px) {
      margin-bottom: 8px;
    }
  }
`;

type MessageProps = {
  onClose?: () => void;
};

export const Message = (props: MessageProps) => {
  return (
    <MessageContainer display="flex">
      <Typo variant="body1" color="common">
        The website makes use of{' '}
        <a href="https://drive.google.com/file/d/1-WR29meEj97LpgdAHJ2LbALHgxab2o3N/view">cookies</a>.
        Review <a href="https://drive.google.com/file/d/1-WR29meEj97LpgdAHJ2LbALHgxab2o3N/view">data privacy</a> for more
        details.
      </Typo>
      <Button data-cy="cookie-btn" text="Accept" onClick={props.onClose} />
    </MessageContainer>
  );
};
